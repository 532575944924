/* eslint-disable */

export function setCookie(cname, cvalue, expsec) {
  var expires = "";
  if (!expsec) {
    var d = new Date();
    d.setTime(d.getTime() + (expsec * 1000));
    expires = "expires=" + d.toUTCString() + "; ";
  }
  document.cookie = cname + "=" + cvalue + "; " + expires + "path=/";
}

export function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ')
      c = c.substring(1);
    if (c.indexOf(name) === 0)
      return c.substring(name.length, c.length);
  }
  return "";
}

export function setInfo() {
  var refreshInfo = false;

  //Si on a pas d'info
  if (getCookie('arrival_url') === '') {
    refreshInfo = true;
    //console.log('Pas d\info de tracking');
  }

  //ou si on vient d'un autre site
  else if (document.referrer) {
    //console.log('On a un referrer');
    if (!document.referrer.match(new RegExp('^https?:\/\/' + window.location.hostname, 'i'))) {
      //console.log('Referrer d\'un site différent');
      refreshInfo = true;
    }
  }
  //Si on a pas de referrer mais une url d'arriver avec tracking
  else if (window.location.href.match(/((\?|\&)src=)|((\?|\&)utm_source=)/i)) {
    //console.log('Url d\'arrivée contient des infos de tracking');
    if (window.location.href !== getCookie('arrival_url')) {
      //console.log('Les infos de tracking sont différentes de celles enregistrées');
      refreshInfo = true;
    }
  }

  //on raffraichi les infos
  if (refreshInfo) {
    //console.log('On raffraichi les infos de tracking');
    setCookie('arrival_url', window.location.href);
    setCookie('referer', document.referrer);
  } else {
    //console.log('Pas de raffraichissement des infos de tracking');
  }
}