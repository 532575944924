export const constructBreadcrumb = (post) => {
    let items = post?.seo?.breadcrumbs.map((item, index) => {
        return {
            "@type": "ListItem",
            "position": index + 1,
            "name": item.text,
            "item": 'https://normandienuisibles.fr' + item.url
        }
    });

    if(!items){
        items = {
            "@type": "ListItem",
            "position": 1,
            "name": "Accueil",
            "item": 'https://normandienuisibles.fr/'
        }
    }

    return {
        "@context": "https://schema.org/",
        "@type": "BreadcrumbList",
        "itemListElement": items,
    }
}

export const getBreadcrumb = (post) => {
    return JSON.stringify(constructBreadcrumb(post));
}