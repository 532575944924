import React, {useEffect, useRef, useState} from "react"
import { Link, useStaticQuery, graphql } from "gatsby";

import "./header.scss";
import { isContactUsActive } from "../functions";
import { navigate } from "gatsby";

const HeaderInner = ({ post, isHomePage, headerEl, isFixed, headerAppear }) => {

    const {
        allWpMenuItem: {
            nodes
        },
        wp: {
            customisationGNRale: {
                acfCustomisation: { logo }
            }
        },
    }= useStaticQuery(graphql`
        query getMenuPrincipalItems {
            allWpMenuItem(filter: { menu: {node: {slug: {in: "menu-principal"}}}}) {
                nodes {
                  id
                  label
                  url
                  parent: parentId
                }
            }
            wp {
                customisationGNRale {
                    acfCustomisation {
                      logo {
                        fieldGroupName
                        noir {
                          altText
                          localFile {
                              childImageSharp {
                                fixed(width: 165, jpegQuality: 10) {
                                  src
                                }
                              }
                          }
                        }
                        blanc {
                          altText
                          localFile {
                              childImageSharp {
                                fixed(width: 165, jpegQuality: 10) {
                                  src
                                }
                              }
                          }
                        }
                      }
                    }
                }
            }
        }
    `);

    const hierarchicalList = flatListToHierarchical( nodes );

    return (
        <header className={'menu-principal ' + `${isFixed ? 'fixed ' : ''}` + `${headerAppear ? 'appear' : ''}`} ref={ headerEl }>
            <div className="inner">
                <div className="block-left">
                    <h1>
                        <Link to="/">
                            {logo ?
                                <img src={ logo.noir.localFile.childImageSharp.fixed.src }
                                     alt={ logo.noir.altText } /> :
                                null
                            }
                        </Link>
                    </h1>
                    <nav>
                        <ul className="first-level">
                            {nodes &&
                                <MenuList nodes={ hierarchicalList }/>
                            }
                        </ul>
                    </nav>
                </div>
                <div className="block-right">
                    {!isContactUsActive(post) ?
                        <Link className="call-to-action" to="/contactez-nous">Contactez-nous</Link> : null
                    }
                </div>
            </div>
        </header>
    )
}

const Header = ({}) => {

    const headerEl = useRef(null);
    const headerElFk = useRef(null);

    const [ headerFixed, setHeaderFixed ] = useState(false);
    const [ headerAppear, setHeaderAppear ] = useState(false);

    const [ heightEl, setHeightEl ] = useState("0px");

    let scrollObserver = ([ entry ]) => {
        setHeaderFixed(!entry.isIntersecting)
    }

    let getHeightInner = () => {
        setHeightEl(headerEl.current.getBoundingClientRect().height + "px");
    }

    useEffect(() => {

        if (window.matchMedia('(max-width: 767px)').matches) {
            window.onscroll = () => {
                if(window.scrollY > 10){
                    setHeaderFixed(true)
                } else {
                    setHeaderFixed(false)
                }
            }

            return () => {
                window.onscroll = () => {};
            }
        } else {

            const observer = new IntersectionObserver(
                scrollObserver, {
                    threshold: [0]
                });

            if (headerEl.current) {
                getHeightInner();
                window.onresize = () => getHeightInner();

                observer.observe(headerElFk.current);
            }
            return () => {
                observer.disconnect()
            }
        }
    },[headerFixed]);

    return(
        <div className="header-fk" style={{height: heightEl} } ref={ headerElFk }>
            <HeaderInner isFixed={ headerFixed } headerEl={ headerEl } headerAppear={ headerAppear }/>
        </div>
    )
}

export default Header;

export const MenuList = ({ nodes }) => {
    return (
        nodes.map(( item, key ) => <MenuListItem item={ item } key={ key } />)
    )
}

const MenuListItem = ({ item}) => {

    let [ seeChildrens, setSeeChildrens ] = useState(false);

    let mouseEnter = () => {
        setSeeChildrens(true)
    }

    let mouseLeave = () => {
        setSeeChildrens(false);
    }

    if(item.children.length === 0) {
        return (
            <li onClick={() => navigate( item.url )}>
                <span>
                    <Link to={ item.url }>{ item.label }</Link>
                </span>
            </li>
        )
    }

    return(
        <li onMouseEnter={ mouseEnter } onMouseLeave={ mouseLeave }>
            <span>
                <Link to={ item.url }>{ item.label }</Link>
            </span>
            <ul className={`child-menu ${seeChildrens ? 'show': ''}`}>
                <MenuList nodes={item.children}/>
            </ul>
        </li>
    )
}

export const flatListToHierarchical = (
    data = [],
    {
        idKey = 'id',
        parentKey = 'parent',
        childrenKey = 'children'
    } = {}
) => {
    const tree = [];
    const childrenOf = {};

    data.forEach((item) => {

        const newItem = {...item};
        const { [ idKey ]: id, [ parentKey ]: parentId = 0 } = newItem;

        childrenOf[id] = childrenOf[id] || [];
        newItem[childrenKey] = childrenOf[id];
        parentId
            ? (
                childrenOf[parentId] = childrenOf[parentId] || []
            ).push(newItem)
            : tree.push(newItem);
    });

    return tree;
};