import React, { useEffect, useState } from "react";
import "./footer.scss";
import {graphql, useStaticQuery} from "gatsby";
import { flatListToHierarchical, MenuList } from "./header";
import logoNormandie from "./images/region-normandie-logo.jpg";
import {sendWcbEvent} from "../functions";

const Footer = ({ isHomePage, post }) => {

    //const reCAPTCHA_site_key = "6Lf41ygaAAAAAISMztrLpSFCfZFZ8g3NRhCAy18o";

    const [marginLocalisation, setMarginLocalisation] = useState(0);

    const {
        allWpMenuItem: {
            nodes
        },
    }= useStaticQuery(graphql`
        query getMenuFooterItems {
            allWpMenuItem(filter: { menu: { node: {slug: {in: "menu-footer" }}}}) {
                nodes {
                  id
                  label
                  url
                  parent: parentId
                }
            }
        }
    `);

    const hierarchicalList = flatListToHierarchical( nodes );

    useEffect(() => {
        function handleResize() {
            let heightLocalisation = document.querySelector(".localisation").getBoundingClientRect().height;
            setMarginLocalisation(heightLocalisation);
        }

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return[
        <footer className="footer" key={ 1 }>
            {(!post || (!isHomePage && post.slug !== "contactez-nous")) &&
            <section className="urgence">
                <div className="inner">
                    <div className="bloc-left">
                        <h1>
                            <span className="yellow-words">Une urgence ? </span>
                            <span>Appelez-nous !</span>
                        </h1>
                    </div>
                    <div className="bloc-right">
                        <div>
                            <a href="tel:+33695434091" className="call-to-action toTel" onClick={() => sendWcbEvent()}>06 95 43 40 91</a>
                            <span className="local">*Prix d'un appel local</span>
                        </div>
                        <div>
                            <span className="rappel">Rappel gratuit et immédiat</span>
                        </div>
                    </div>
                </div>
            </section>
            }
            <section className="menus" style={ { marginBottom: marginLocalisation - 2 +  "px" } }>
                <div className="inner">
                    <div className="copyright">
                        <b>Normandie Nuisibles</b> @ Copyright 2021
                    </div>
                    <nav>
                        <ul className="first-level">
                            {nodes &&
                            <MenuList nodes={ hierarchicalList }/>
                            }
                        </ul>
                    </nav>
                </div>
            </section>
            <section className="localisation">
                <div className="inner">
                    <div className="logo-normandie">
                        <img src={ logoNormandie } alt="Nous intervenons dans toute la Basse-Normandie" />
                    </div>
                    <div className="content">
                        Nous intervenons dans les départements de la Manche, le Calvados et l'Orne, situés en Basse-Normandie.
                        Les villes dans lesquelles nous pouvont intervenir peuvent se trouver aux alentours de
                        Granville (50), Coutances (50), Saint-Lô (50), Caen (14), Alençon (61), Lisieux (14), Hérouville-Saint-Clair (14), La Ferté-Macé (61), Valognes (50), Cherbourg-Octeville (50), Saint-Lô (50), Flers (61), Équeurdreville-Hainneville (50), Tourlaville (50), Honfleur (14), Argentan (61), Bayeux (14), Ouistreham (14), Vire (14), Ifs (14), Mondeville (14), L'Aigle (61), Falaise (14), Avranches (50).
                    </div>
                </div>
            </section>
        </footer>,
        <ExternalScripts key={ 2 } />
    ]
}

export default Footer;


const ExternalScripts = () => {
    useEffect(() => {

        // add tarteaucitron gtag.js
        if(!document.querySelector('.tarteaucitron-nuisibles')) {
            const scriptNuisibles = document.createElement("script");
            scriptNuisibles.classList.add('tarteaucitron-nuisibles');

            const script = document.createElement('script');

            script.src = '/tarteaucitron/tarteaucitron.js';
            script.async = true;
            
            script.onload = () => {
                console.log('ici meme');
                console.log('tarteaucitron => ', window.tarteaucitron);
                window.tarteaucitron.init(
                    {
                    privacyUrl: "", /* Privacy policy url */
                    "bodyPosition": "bottom", /* or top to bring it as first element for accessibility */
                    "hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
                    "cookieName": "tarteaucitron", /* Cookie name */
                    "orientation": "middle", /* Banner position (top - bottom) */
                    "groupServices": false, /* Group services by category */
                    "showDetailsOnClick": true, /* Click to expand the description */
                    "serviceDefaultState": "wait", /* Default state (true - wait - false) */          
                        "showAlertSmall": false, /* Show the small banner on bottom right */
                        "cookieslist": false, /* Show the cookie list */             
                    "closePopup": false, /* Show a close X on the banner */
                    "showIcon": true, /* Show cookie icon to manage cookies */
                    //"iconSrc": "", /* Optionnal: URL or base64 encoded image */
                    "iconPosition": "BottomRight", /* BottomRight, BottomLeft, TopRight and TopLeft */
                        "adblocker": false, /* Show a Warning if an adblocker is detected */         
                    "DenyAllCta" : true, /* Show the deny all button */
                    "AcceptAllCta" : true, /* Show the accept all button when highPrivacy on */
                    "highPrivacy": true, /* HIGHLY RECOMMANDED Disable auto consent */
                    "alwaysNeedConsent": false, /* Ask the consent for "Privacy by design" services */           
                        "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */
                        "removeCredit": false, /* Remove credit link */
                        "moreInfoLink": true, /* Show more info link */
                    "useExternalCss": false, /* If false, the tarteaucitron.css file will be loaded */
                    "useExternalJs": false, /* If false, the tarteaucitron.js file will be loaded */
                        //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */              
                    "readmoreLink": "", /* Change the default readmore link */
                    "mandatory": true, /* Show a message about mandatory cookies */
                    "mandatoryCta": true, /* Show the disabled accept button when mandatory on */
                    //"customCloserId": "", /* Optional a11y: Custom element ID used to open the panel */
                    "googleConsentMode": true, /* Enable Google Consent Mode v2 for Google ads and GA4 */
                    "partnersList": false /* Show the number of partners on the popup/middle banner */
                });

                scriptNuisibles.type = 'text/javascript';
                scriptNuisibles.text = `window.tarteaucitron.user.gtagUa = 'G-HCRRMMGNDF';

                (window.tarteaucitron.job = window.tarteaucitron.job || []).push('gtag');`;
                (tarteaucitron.job = tarteaucitron.job || []).push('gcmanalyticsstorage');
                (tarteaucitron.job = tarteaucitron.job || []).push('gcmadstorage');
                (tarteaucitron.job = tarteaucitron.job || []).push('gcmadsuserdata');

                document.body.appendChild(scriptNuisibles);

                window.tarteaucitron.load()
            };

            document.body.appendChild(script);
        }

    }, []);

    return null;
}

/*
const ExternalScripts = ({ reCAPTCHA_site_key }) => {

    useEffect(() => {

        if(!document.querySelector('.recaptcha-nuisibles')) {
            const script = document.createElement("script");
            script.classList.add('recaptcha-nuisibles');
            script.src = `https://www.google.com/recaptcha/api.js?render=${reCAPTCHA_site_key}`;
            script.async = true;
            document.body.appendChild(script);
        }
    }, [reCAPTCHA_site_key]);

    return null;
}*/