import {getCookie} from "./cookies";

export let isContactUsActive = (post) => {
    if(!post) return false;
    return post.slug === "contactez-nous";
}

export const sendWcbEvent = () => {
    const arrival_url = getCookie('arrival_url');
    const referer = getCookie('referer');
    const current_url = window.location.href;

    window.gtag('event', 'appel_entrant_action', {
        event_category: 'contact_event',
        event_label: 'appel_entrant_action',
        arrival_url,
        referer,
        current_url
    });
}