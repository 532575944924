import React, {useEffect} from "react"
import Header from "./header";
import Footer from "./footer";
import { setInfo } from '../cookies';

const Layout = ({ isHomePage, post, children }) => {

  useEffect(() => {
      setInfo();
  }, []);

  return (
    <div className="global-wrapper" data-is-root-path={isHomePage}>
     <Header isHomepage={ isHomePage } post={ post } />
     <main>{children}</main>
     <Footer isHomePage={ isHomePage } post={ post } />
    </div>
  )
}

export default Layout
